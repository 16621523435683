<template>
    <div class="view">
        <div v-if='loading || !orderInfo' class="loadingBox">
            <van-loading type="spinner" color="#1989fa" vertical>加载中...</van-loading>
        </div>
        <van-pull-refresh v-model="refreshLoading" @refresh="refresh" v-else>
            <div class="orderInfo">
                <div class="title">
                    <div class="status">{{ orderInfo | filterStatus }}</div>
                    <div class="callPhone" @click="callPhone(1)">
                        <van-icon name="phone-o" size='16'/>
                        <span>致电平台</span>
                    </div>
                </div>
                <div class="line"></div>
                <div class="order">
                    <div class="order_title" @click="order_upIcon = !order_upIcon">
                        <span>订单信息</span>
                        <van-icon name="arrow-down" :class=" order_upIcon ? 'up' : 'down' " />
                    </div>
                    <div v-if='order_upIcon'>
                        <div class="order_line"></div>
                        <div class="info_li">
                            <div>
                                <span>订单号码： </span>
                                <span>{{ orderInfo.no }}</span>
                            </div>
                            <div class="callPhone" @click="onCopy">
                                <van-icon name="records" size='12'/>
                                <span>复制</span>
                            </div>
                        </div>
                        <div class="info_li" v-if='countDownTime > 0'>
                            <div>
                                <span>下单时间： </span>
                                <span>{{ orderInfo.create_time | filterTime }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-else>
                            <div>
                                <span>救援类型： </span>
                                <span>{{ orderInfo.type | filterType }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div v-if="orderInfo.cid == 6">
                                <span>车主信息： </span>
                                <span>{{ orderInfo.contact }} </span>
                                <span> {{ orderInfo.phone }}</span>
                            </div>
                            <div v-else>
                                <span>车主信息： </span>
                                <span>{{ orderInfo.contact | filterContact }} </span>
                                <span> {{ orderInfo.phone | filterUserPhone }}****</span>
                            </div>
                            <div class="callPhone" @click="callPhone(2)" v-if='orderInfo.status && orderInfo.status != 0 && orderInfo.status != 1 && orderInfo.status != 4 && orderInfo.status != 5 && orderInfo.status != 6 && orderInfo.status != 7 && orderInfo.status != 8 && orderInfo.status != 34'>
                                <van-icon name="phone-o" size='12'/>
                                <span>致电客户</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 不合格照片 -->
                <div class="order" v-if="unqualifiedImg.length != 0 && photoTemplate.length != 0">
                    <div class="order_title" @click="photos_upIcon = !photos_upIcon">
                        <span>不合格照片</span>
                        <van-icon name="arrow-down" :class=" photos_upIcon ? 'up' : 'down' " />
                    </div>
                    <div v-if='photos_upIcon'>
                        <div class="order_line"></div>
                        <Unqualified 
                            ref="unqualified"
                            :unqualifiedImg='unqualifiedImg'
                            :orderInfo='orderInfo'
                            :defaultPhoto="photoTemplate"
                            :orderId="order"
                            @success='getDetail'/>
                    </div>
                </div>
                <div class="order" v-if='countDownTime > 0 && orderInfo.status != 5'>
                    <div class="order_title" @click="rescue_upIcon = !rescue_upIcon">
                        <span>救援信息</span>
                        <van-icon name="arrow-down" :class=" rescue_upIcon ? 'up' : 'down' " />
                    </div>
                    <div v-if='rescue_upIcon'>
                        <div class="order_line"></div>
                        <div class="info_li">
                            <div>
                                <span>救援类型： </span>
                                <span>{{ orderInfo.type | filterType }}</span>
                            </div>
                            <div class="callPhone" @click='previewImg' v-if='orderInfo.customerImg.length != 0 && orderInfo.status != 4 && orderInfo.status != 5 && orderInfo.status != 6 && orderInfo.status != 7 && orderInfo.status != 8 && orderInfo.status != 34'>
                                <van-icon name="photo-o" size='12'/>
                                <span>查看现场照片</span>
                            </div>
                            <div class="callPhone" @click="uploadPhotos" v-if='(orderInfo.status == 4 || orderInfo.status == 6 || orderInfo.status == 34) && orderInfo.picture.length < 20'>
                                <!-- <van-icon name="photo-o" size='12'/> -->
                                <span>补传照片</span>
                                （<van-count-down style="color: #fff;" :time="countDownTime" @finish='countDownFinish'/>）
                            </div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>故障车牌： </span>
                                <span>{{ orderInfo.car_no }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>品牌型号： </span>
                                <span>{{ orderInfo.car_model ? orderInfo.car_model : '-' }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if="orderInfo.type == 3 && orderInfo.tire_model">
                            <div>
                                <span>轮胎型号： </span>
                                <span>{{ orderInfo.tire_model }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if="isDoubleAddress">
                            <div>
                                <span>免拖公里数： </span>
                                <span>{{ orderInfo.free_milage ? orderInfo.free_milage : '--' }}</span>
                                <span v-if="!isNaN(Number(orderInfo.free_milage))"> 公里</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                故障车位置：
                            </div>
                            <div class="callPhone" @click="openMap(1)" v-if='orderInfo.status != 4 && orderInfo.status != 5 && orderInfo.status != 6 && orderInfo.status != 7 && orderInfo.status != 8 && orderInfo.status != 34'>
                                <van-icon name="location-o" size='12'/>
                                <span>导航前往故障车位置</span>
                            </div>
                        </div>
                        <div class="info_li address">
                            <div class="address_text">
                                {{ orderInfo.address ? orderInfo.address : '-' }}
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if="isDoubleAddress">
                            <div>
                                拖车目的地：
                            </div>
                            <div class="callPhone" @click="openMap(2)" v-if='orderInfo.status != 4 && orderInfo.status != 5 && orderInfo.status != 6 && orderInfo.status != 7 && orderInfo.status != 8 && orderInfo.status != 34'>
                                <van-icon name="location-o" size='12'/>
                                <span>导航前往拖车目的地</span>
                            </div>
                        </div>
                        <div class="info_li address" v-if="isDoubleAddress">
                            <div class="address_text">
                                {{ orderInfo.destination ? orderInfo.destination : '-' }}
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li" v-if="orderInfo.service_remark">
                            <div>
                                <span>服务备注： </span>
                                <span>{{ orderInfo.service_remark }}</span>
                            </div>
                            <div></div>
                        </div>
                        <div class="info_li">
                            <div>
                                <span>订单备注： </span>
                                <span>{{ orderInfo.remark }}</span>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <!-- 服务照片 orderInfo.status == 3 || orderInfo.status == 25 ||  -->

                <p class="photoTips" v-if='orderInfo.status == 24 || orderInfo.status == 26'>必传照片请按由上到下的顺序依次上传</p>
                <div class="uploadImgs" v-if='orderInfo.status == 24 || orderInfo.status == 26'>
                    <div class="img_li" v-for="(item, index) in uploadImgArr" :key="index">
                        <div class="tipText">
                            <div class="tipName">
                                <span :class="['needText', { 'needColor': item.is_need == 1 }]">{{ item.is_need == 1 ? '必传' : '非必传' }}  </span>
                                <span class="nameText">{{ item.name }}</span>
                            </div>
                            <div class="tipRemark" v-show="item.remark">拍照要求：{{ item.remark }}</div>
                        </div>
                        <div class="uploadImgBox">
                            <div class="imageBox">
                                <img :src="item.picture" mode="" @click="preImg(item.picture)"/>
                                <span class="textImg">示例图</span>
                                <!-- <span class="imgTips">
                                    <span class="tipIcon" v-if="item.is_need == 1">*</span>
                                    {{ item.name }}
                                </span> -->
                            </div>
                            <div class="imageBox uploaderBox">
                                <van-uploader
                                    :upload-text="item.disabledUpload?'请先上传前面的必传照片':'上传照片'"
                                    :before-delete="(file) => delImage(file, index, item.id)"
                                    :after-read="(file) => afterRead(file, index, item.key, item.name)"
                                    max-count='1'
                                    :disabled="item.disabledUpload"
                                    v-model="uploadImgArr[index].imgList"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 电子签名 -->
                <div class="order" v-if="orderInfo.status == 24 || orderInfo.status == 26">
                    <div class="order_title" @click="sign_upIcon = !sign_upIcon">
                        <span>电子签名</span>
                        <van-icon name="arrow-down" :class=" sign_upIcon ? 'up' : 'down' " />
                    </div>
                    <template v-if="sign_upIcon">
                        <div class="order_line"></div>
                        <div class="sginBox" v-if="orderInfo.status == 24">
                            <div class="sign_title">
                                <span class="sign_title_name">
                                    <span class="tipIcon" v-if="ownersignConfig.is_need == 1">*</span>
                                    {{ ownersignConfig.name }}
                                </span>
                                <span class="sign_title_btn" v-if="owner_sign" @click="toSign('owner_sign')">重新签名</span>
                            </div>
                            <div class="signInfo" @click="owner_sign ? preImg(owner_sign) : toSign('owner_sign')">
                                <img :src="owner_sign" alt="" class="signImg" v-if="owner_sign"/>
                                <span class="sign_text" v-else>签名区域</span>
                            </div>
                        </div>
                        <div class="sginBox" v-if="orderInfo.status == 26">
                            <div class="sign_title">
                                <span class="sign_title_name">
                                    <span class="tipIcon" v-if="meetsignConfig.is_need == 1">*</span>
                                    {{ meetsignConfig.name }}
                                </span>
                                <span class="sign_title_btn" v-if="meet_sign" @click="toSign('meet_sign')">重新签名</span>
                            </div>
                            <div class="signInfo" @click="meet_sign ? preImg(meet_sign) : toSign('meet_sign')">
                                <img :src="meet_sign" alt="" class="signImg" v-if="meet_sign"/>
                                <span class="sign_text" v-else>签名区域</span>
                            </div>
                        </div>
                        <div class="sginBox">
                            <div class="sign_title">
                                <span class="sign_title_name">
                                    <span class="tipIcon" v-if="driversignConfig.is_need == 1">*</span>
                                    {{ driversignConfig.name }}
                                </span>
                                <span class="sign_title_btn" v-if="driver_sign" @click="toSign('driver_sign')">重新签名</span>
                                <span class="sign_title_btn" v-if="!driver_sign && locDriverSign" @click="uploadCacheSign">使用上次签名</span>
                            </div>
                            <div class="signInfo" @click="driver_sign ? preImg(driver_sign) : toSign('driver_sign')">
                                <img :src="driver_sign" alt="" class="signImg" v-if="driver_sign"/>
                                <span class="sign_text" v-else>签名区域</span>
                            </div>
                        </div>
                    </template>
                </div>
                <!-- 其它照片 -->
                <div class="otherImgs" v-if='orderInfo.status == 24'>
                    <van-uploader 
                        upload-text='其它照片'
                        multiple
                        :after-read="otherAfterRead"
                        :before-delete='delOtherImage'
                        result-type='file'
                        v-model="otherImg.imgList"/>
                </div>
                <div class="payTips" v-if="orderInfo.is_cash == 1">
                    <van-icon name="warning" color="#FB421A" size="16"/>
                    <span class="payTipText" v-if="orderInfo.pay_status == 0" @click="openWxPay">用户现金单：需收款{{ orderInfo.pay_money }}元</span>
                    <span class="payTipText" v-if="orderInfo.pay_status == 2" @click="openWxPay">用户现金单：需收尾款{{ orderInfo.balance_money }}元，点击生成收款码</span>
					<span class="payTipText" v-if="orderInfo.pay_status == 1 || orderInfo.pay_status == 3">用户现金单：已收款</span>
                </div>
                <div class="footer" v-if="orderInfo.cancel_waiting != 1 && orderInfo.cancel_waiting != 2">
                    <div class="btn disableBtn" v-if='orderInfo.status == 1'>待平台受理</div>
                    <div class="btn nextBtn" v-if='orderInfo.status == 31 || orderInfo.status == 12' @click="receiving">立即接单</div>
                    <div class="btn nextBtn" v-if='orderInfo.status == 11 || orderInfo.status == 22 || orderInfo.status == 23' @click="getDetail('valid')">到达救援地</div>
                    <div class="btn nextBtn" v-if='orderInfo.status == 24' @click="orderComplete">
                        {{ isDoubleAddress ? '前往拖车目的地' : '服务完成' }}
                    </div>
                    <template v-if='isDoubleAddress'>
                        <div class="btn nextBtn" @click="getDetail('valid')" v-if='orderInfo.status == 3 || orderInfo.status == 25'>
                            到达目的地
                        </div>
                        <div class="btn nextBtn" v-if="orderInfo.status == 26" @click="arrvieEndValidPhoto">
                            服务完成
                        </div>
                    </template>
                    <div class="btn nextBtn" @click="downloadAPP" v-if='orderInfo.status == 4 || orderInfo.status == 5 || orderInfo.status == 6 || orderInfo.status == 7 || orderInfo.status == 8 || orderInfo.status == 34'>
                        下载专属接单APP
                    </div>
                </div>
            </div>
        </van-pull-refresh>
        <!-- 已受理接单填写手机号 -->
        <van-dialog 
            v-model="driver_phone_show" 
            title="立即接单" 
            show-cancel-button 
            confirmButtonColor='#357EFE' 
            :beforeClose='handImproveInfo'>
            <div class="telInput">
                <van-field v-model="driverPhone" type="tel" label-width='140' required label="救援技师手机号：" placeholder="请输入救援技师手机号" maxlength='11'/>
            </div>
        </van-dialog>
        <!-- 效验手机号 -->
        <!-- <van-dialog 
            :showConfirmButton='false'
            v-model="mobile_valid_dialog">
            <div class="validBox">
                <van-icon name="close" class="dialog_close_icon" size='22' color='#7F7F7F' @click="closeDialog"/>
                <div class="phoneInput_title">输入客户手机 {{ orderInfo.phone | filterUserPhone }} 后4位数</div>
                <div class="u-pop-message">
                    <div 
                        v-for="(item, index) in valid_length" 
                        :key='index' 
                        @click="inputFocus"
                        :class="['valid_li', validValue.length == index ? 'activeFocus' : '' ]">
                        <span>{{ validValue[index] }}</span>
                    </div>
                </div>
                <div class="phoneError">
                    <div class="error_text" v-if="!checkPhoneStatus">输入错误</div>
                </div>
            </div>
        </van-dialog> -->
        <div class="valid_input">
            <van-field v-model="validValue" ref='input' type="digit" :maxlength='valid_length' @input="changeInput"/>
        </div>
        <!-- 联系客户确认拨打手机号 -->
        <van-dialog 
            v-model="affirm_phone_dialog" 
            title="您的号码将被加密拨出" 
            show-cancel-button 
            confirmButtonColor='#357EFE'
            confirmButtonText='呼叫'
            :beforeClose='affirmMonbile'>
            <div class="affirm_phone_box">
                <van-field 
                    v-model="myMobile"
                    ref='inputRef' 
                    type="tel" 
                    label-width='120' 
                    required 
                    label="确认本机号码：" 
                    placeholder="请输入本机号码" 
                    maxlength='11'/>
                <van-icon name="edit" size='20' color='#357EFE' @click="editPhone"/>
            </div>
        </van-dialog>
        <!-- 工单确认 -->
        <van-dialog 
            v-model="workOrder_dialog" 
            title="确认救援信息" 
            show-cancel-button 
            confirmButtonColor='#357EFE'
            confirmButtonText='提交'
            cancelButtonText='关闭'
            :beforeClose='submitWorkOrder'>
            <div class="workOrder_box" v-if='isDoubleAddress'>
                <div class="workOrder_li">
                    <span>出发地到救援地行驶里程：</span>
                    <van-field v-model="workForm.go_distance" class="short_input" type='number'/>
                    <span class="km">km</span>
                </div>
                <div class="workOrder_li">
                    <span>救援地到目的地行驶里程：</span>
                    <van-field v-model="workForm.get_distance" class="short_input" type='number'/>
                    <span class="km">km</span>
                </div>
                <div class="workOrder_li">
                    <span>使用辅轮：</span>
                    <van-field v-model="workForm.use_wheels" class="long_input" type='number'/>
                    <span class="km">个</span>
                </div>
                <div class="workOrder_li">
                    <span>救援环境：</span>
                    <div class="long_input dropdownMenu" @click="dropdownItemShow = !dropdownItemShow">
                        <div>{{ workForm.science | filterEnvironment }}</div>
                        <van-icon name="arrow-down" :class=" dropdownItemShow ? 'up' : 'down' " />
                    </div>
                    <span class="km"></span>
                </div>
            </div>
            <div class="workOrder_box" v-else>
                <div class="workOrder_li">
                    <span>出发地到救援地行驶里程：</span>
                    <van-field v-model="workForm.go_distance" class="short_input" type='number'/>
                    <span>km</span>
                </div>
            </div>
        </van-dialog>
        <!-- 选择救援环境 -->
        <van-popup v-model="dropdownItemShow" round position="bottom">
            <van-picker
                show-toolbar
                :columns="environment"
                @cancel="dropdownItemShow = false"
                @confirm="popupConfirm"/>
        </van-popup>
        <!-- 补传照片 -->
        <van-dialog 
            v-model="upload_photos_dialog" 
            title="补传照片"
            show-cancel-button 
            confirmButtonColor='#357EFE'
            confirmButtonText='提交'
            cancelButtonText='关闭'
            :beforeClose='submitPhotos'>
            <div class="photosBox">
                <van-uploader 
                    upload-text='补传照片'
                    multiple
                    max-count='5'
                    :after-read="photosAfterRead"
                    result-type='file'
                    v-model="photos"/>
            </div>
        </van-dialog>
        <!-- 到达救援地照片效验 -->
        <van-dialog 
            :showConfirmButton='false'
            width="330"
            v-model="mobile_valid_dialog">
            <ValidScene 
                v-if="mobile_valid_dialog"
                :orderInfo="orderInfo" 
                :orderId="order"
                @success="getDetail"
                @close='mobile_valid_dialog=false'/>
        </van-dialog>
        <!-- 签名 -->
        <Sign 
            v-if="signVsi" 
            @close='signVsi = false' 
            @success="signSuccess"
            :signKey='signKey'
            :orderId="order"/>
        <!-- 支付二维码 -->
        <van-dialog 
            :title="`二维码收款 ￥${orderInfo.balance_money}`" 
            :showConfirmButton='false'
            width="330"
            v-model="wxPayDialog">
            <wx-pay-qrcode v-if="wxPayDialog" :orderInfo="orderInfo" @success="paySuccess" @close="wxPayDialog = false"></wx-pay-qrcode>
        </van-dialog>

        <!-- 接单信息完善 -->
        <van-dialog 
            :showConfirmButton='false'
            width="330"
            v-model="driverInfoVsi">
            <driver-info 
                v-if="driverInfoVsi"
                :order="order"
                :location="location"
                :configData="configData"
                :disDriverInfo="driverInfo"
                :orderInfo="orderInfo" 
                :driverImproveInfo='driverImproveInfo'
                @success='improveSuccess'
                @close='closeImprove'>
            </driver-info>
        </van-dialog>

        <!-- nps照片补传 -->
        <van-dialog 
            :showConfirmButton='false'
            width="330"
            v-model="npsUpload">
            <div v-if="npsUpload">
                <div class="npsTitle">上传{{ uploadImgArr[0].name }}</div>
                <div class="orderInfo">
                    <div class="npsTips">{{ uploadImgArr[0].remark }}</div>
                    <div class="uploadImgs">
                        <div class="uploadImgBox">
                            <div class="imageBox">
                                <img :src="uploadImgArr[0].picture" mode="" @click="preImg(uploadImgArr[0].picture)"/>
                                <span class="textImg">示例图</span>
                            </div>
                            <div class="imageBox uploaderBox">
                                <van-uploader 
                                    upload-text='上传nps照片'
                                    :after-read="(file) => afterRead(file, 0, uploadImgArr[0].key, uploadImgArr[0].name)"
                                    max-count='1'
                                    v-model="uploadImgArr[0].imgList"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="npsUploadBtn" v-if="uploadImgArr[0].is_need != 1" @click="npsUpload = false">暂不上传</div>
            </div>
        </van-dialog>
        
    </div>
</template>

<script>
import { mapState } from "vuex";
import Data from '@/utils/data.js';
import {
  getLink,
  orderDetail,
  driverAccept,
  callContact,
  validMobile,
  arrivestart,
  driverUploadPic,
  toend,
  arriveend,
  confirmWork,
  orderFinish,
  orderPhotoConfig,
  driverCheck,
  licenseOCR,
  orderRemark
} from '@/apis/checkList';
import { getQueryString, formatTime, validLngLat, getGeoLocation } from '@/utils/index';
import { generateCanvasImg, initWatermarkConfigData } from '@/utils/uploadOSS';
import { ImagePreview } from 'vant';

import ValidScene from '@/components/checkList/validScene';
import Unqualified from '@/components/checkList/unqualified';
import Sign from '@/components/checkList/sign';
import wxPayQrcode from '@/components/checkList/wxPayQrcode';
import DriverInfo from '@/components/checkList/driverInfo'


export default {
    components: {
        ValidScene,
        Unqualified,
        Sign,
        wxPayQrcode,
        DriverInfo
    },
    data(){
        return {
            order: '',          //加密订单标识
            loading: false,
            refreshLoading: false,
            orderInfo: '',
            orderRemark: [],     //订单备注信息列表
            order_upIcon: true,         //订单信息
            rescue_upIcon: true,        //救援信息
            photos_upIcon: true,        //不合格照片
            sign_upIcon: true,          //电子签名
            driver_phone_show: false,      //服务商接单填写技师手机号
            driverPhone: '',        //技师手机号
            mobile_valid_dialog: false,     //到达救援地效验手机号
            affirm_phone_dialog: false,     //联系客户确认拨打手机号
            workOrder_dialog: false,         //工单确认
            upload_photos_dialog: false,      //补传照片
            photos: [],
            myMobile: '',
            valid_length: 4,
            validValue: '',
            checkPhoneStatus: true,
            uploadImgArr: [],
            otherImg: {
                key: 'other', //其他照片
                imgList: []
            },
            location: '',           //定位信息
            // location: {
            //     lat: 22.222222,
            //     lng: 113.1115555,
            //     address: '测试地址'
            // },
            environment: [
                { text: '车在路面', value: 0 },
                { text: '车在地库', value: 1 },
                { text: '未知', value: '' },
            ],
            workForm: {
                use_wheels: '',         //辅轮数
                science: '',            //救援环境
                go_distance: '',        //去程
                get_distance: '',       //背程
            },
            dropdownItemShow: false,        //救援环境弹出框
            countDownTime: 1,               //补传照片倒计时 控制是否48小时后状态
            timer: null,
            // 签名
            owner_sign: '',		//车主签名
            meet_sign: '',		//接车人签名
            driver_sign: '',		//司机签名
            locDriverSign: '',      //缓存司机签名
            driversignConfig: '',
            meetsignConfig: '',
            ownersignConfig: '',

            signVsi: false,          //签名组件
            signKey: '',
            // 不合格照片
            unqualifiedImg: [],
            photoTemplate: [],
            device: '',         //设备系统
            isDoubleAddress: false,         //双地址
            wxPayDialog: false,         //收款码

            // 技师信息完善
            driverInfoVsi: false,
            submitType: 1,          // 1 接单  2 上传图片
            carImgIndex: 0,
            driverImproveInfo: {
                phone: '',          //手机号
                name: '',
                uploadKey: '',      //救援车牌照key
                uploadKeyName: '',  //救援车牌照keyname
                validType: '',      //完善信息类型  1: 完善姓名手机号车头照     2：完善姓名手机号   3： 完善车头照
                uploadData: '',     //订单流程车牌照片上传参数
            },
            npsUpload: false,       //完成订单补传nps
        }
    },
    computed: {
        ...mapState({
            'configData': state => state.configData,
            'driverInfo': state => state.driverInfo     //聚合同步技师信息
        })
    },
    created(){
        // let params = {
        //     order_id: '11087',
        //     type: 'driver'        //owner 车主链接 driver 技师链接
        // }
        // getLink(params).then((res)=>{
        //     console.log(res, '获取案件链接');
        //     // 测试加密参数            TzExMDc4    TzExMDgz
        // })
        this.order = getQueryString() ? getQueryString() : 'TzM|';
        this.loading = true;
        this.getDetail();
        this.getLocation();
    },
    mounted(){
        this.locDriverSign = localStorage.getItem('locDriverSign');
        let ran = navigator.userAgent
        let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
        let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        this.device = isAndroid ? 'android' : isIOS ? 'ipone' : 'H5';
        this.$store.dispatch('getDeviceInfo', ran)
    },
    destroyed(){
        clearTimeout(this.timer);
    },
    methods: {
        // 下拉刷新
        refresh(){
            this.getLocation('loading');
            this.getDetail();
        },
        // 定位
        getLocation(type){
            if(type == 'loading'){
                this.$toast.loading({
                    message: '定位中...',
                    forbidClick: true, 
                    duration: 0
                })
            }
            clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
                this.getLocation('noToast');
            }, 60000);
            getGeoLocation().then((res)=>{
                this.location = res;
                this.$toast.clear();
            }).catch((err)=>{
                this.location = '';
                this.$toast.clear();
                if(type != 'noToast' && err){
                    this.$toast(err);
                };
            });
        },
        // 获取订单备注信息
        getOrderRemark(type){
          orderRemark({id:this.orderInfo.id}).then((res)=>{
                if(res.data.code == 20000){
                  this.orderRemark = res.data.data
                }
            }).catch((err)=>{
                this.$toast.clear();
                if(type != 'noToast' && err){
                    this.$toast(err);
                };
            });
        },
        // 订单详情
        getDetail(type){
            let params = {
                order: this.order
            };
            orderDetail(params).then((res)=>{
                if(res.data.code == 0){
                    this.$notify({
                        type: 'danger',
                        message: res.data.info || '失败'
                    })
                    return
                }
                this.orderInfo = res.data.data;
                let status = this.orderInfo.status
                this.isDoubleAddress = this.$jyTypeData(this.orderInfo.type, 'address') == 2;
                document.title = this.orderInfo.firm.name;
                console.log(this.orderInfo, '订单详情')
                if(type == 'valid' && (status == 2 || status == 3 || status == 11 || status == 22 || status == 23 || status == 25)){
                    if(this.orderInfo.is_valid == 1){
                        this.mobile_valid_dialog = true;
                    }else{
                        // 免效验
                        if(status == 25){
                            this.sumitArriveEnd()
                        }else{
                            this.sumitArriveStart()
                        }
                    }
                }
                if(this.orderInfo.customer_id){
                    this.$store.dispatch('getWaterData', this.orderInfo.customer_id);
                }
                // 聚合同步技师信息
                if(this.orderInfo.dispatch_type == 1 && this.orderInfo.dispatch_sync == 1){
                    this.$store.dispatch('getDispatchDriverInfo', this.orderInfo.id);
                }
                // this.orderInfo.status = 5;
                this.orderInfo.customerImg = [];
                if (this.orderInfo && this.orderInfo.picture) {
                    this.orderInfo.picture.map((img) => {
                        if (img.key == 'customer') {
                            this.orderInfo.customerImg.push(img)
                        }
                    })
                };
                if(this.orderInfo.status == 4 || this.orderInfo.status == 6 || this.orderInfo.status == 34){
                    // 补传照片倒计时
                    if(this.orderInfo.picture && (this.orderInfo.picture.length - this.orderInfo.customerImg.length) < 20){
                        let picture_peroid = this.orderInfo.picture_peroid || 48;
                        let nowTime = new Date().getTime();
                        this.countDownTime = (this.orderInfo.end_time + picture_peroid * 60 * 60) * 1000 - nowTime;
                    }else{
                        this.countDownTime = 0;
                    }
                }
                if(this.orderInfo.status == 5){
                    this.countDownTime = 0;
                }
                if(this.orderInfo.status == 12 || this.orderInfo.status == 31 || this.orderInfo.status == 3 || this.orderInfo.status == 24 || this.orderInfo.status == 25 || this.orderInfo.status == 26 || this.orderInfo.status == 4 || this.orderInfo.status == 34){
                    this.filterUploadImg();
                }
                this.getUnqualifiedImg();
                this.loading = false;
                this.refreshLoading = false;
                this.getOrderRemark();
            }).catch(()=>{
                this.refreshLoading = false;
            })
        },
        // 上传照片类型
        async filterUploadImg() {
            let that = this;
            let orderInfo = that.orderInfo;
            let uploadImgConfig = [];

            let photoConfig = '';
            try{
                let res = await orderPhotoConfig({ id: orderInfo.id })
                photoConfig = res.data.data ? res.data.data.data : ''
            }catch(err){

            }
            // console.log(photoConfig, '订单照片配置')
            if(photoConfig){
                uploadImgConfig = photoConfig;
            }else{
                let rescueImg = await this.$store.dispatch('getPhotoKeyTemplate', orderInfo.type); 
                uploadImgConfig = rescueImg ? JSON.parse(JSON.stringify(rescueImg)) : []
            }
            uploadImgConfig = this.changeImgDisabledUpload(uploadImgConfig)
            this.photoTemplate = JSON.parse(JSON.stringify(uploadImgConfig));
            
            let uploadImgArr = [];
            for(let i=0; i<uploadImgConfig.length; i++){
                if(uploadImgConfig[i].step == 1 || uploadImgConfig[i].step == 2){
                    uploadImgArr.push(uploadImgConfig[i])
                }else if(uploadImgConfig[i].step == 4){
                    // 司机签名
					if(uploadImgConfig[i].key == 'driver_sign'){
						this.driversignConfig = uploadImgConfig[i];
					}
					// 接车人签名
					if(uploadImgConfig[i].key == 'meet_sign'){
						this.meetsignConfig = uploadImgConfig[i];
					}
					// 客户签名
					if(uploadImgConfig[i].key == 'owner_sign'){
						this.ownersignConfig = uploadImgConfig[i];
					}
                }
            }
            this.setImgUrl(uploadImgArr);
        },
        setImgUrl(uploadImgArr) {
            let orderInfo = this.orderInfo;
            let picture = orderInfo.picture;
            let imgArr = [];
            // nps照片补传
            let isNpsUrl = false;
            let npsUpload = false;
            let npsItem = '';

            if (picture || picture.length != 0) {
                for(let i=0; i<picture.length; i++){
                    let item = picture[i];
                    let otherValid = true;
                    // 司机签名
                    if (item.key == 'driver_sign') {
                        this.driver_sign = item.doman + item.picture;
                        otherValid = false;
                    }
                    // 接车人签名
                    if (item.key == 'meet_sign') {
                        this.meet_sign = item.doman + item.picture;
                        otherValid = false;
                    }
                    // 客户签名
                    if (item.key == 'owner_sign') {
                        this.owner_sign = item.doman + item.picture;
                        otherValid = false;
                    }
                    // nps 照片
                    if(item.key == 'nps'){
                        isNpsUrl = true;
                    }
                    for(let j=0; j<uploadImgArr.length; j++){
                        let item2 = uploadImgArr[j];
                        if(item2.key == 'nps' && (this.orderInfo.status == 4 || this.orderInfo.status == 34)){
                            npsUpload = true
                            npsItem = item2
                            npsItem.imgList = []
                        }
                        if (item.key == item2.key) {
                            otherValid = false;
                            item2.imgList = [{
                                url: item.doman + item.picture,
                                key: item.key,
                                ser: item.ser
                            }];
                            item2.id = item.id;
                            break;
                        }
                    }
                    if(otherValid){
                        let obj = {
                            url: item.doman + item.picture,
                            id: item.id
                        }
                        imgArr.push(obj);
                    }
                }
                if(isNpsUrl){
                    npsUpload = false;
                }
                this.npsUpload = npsUpload;
            }
            if (this.isDoubleAddress) {
                // 拖车
                if (orderInfo.status == 3 || orderInfo.status == 25 || orderInfo.status == 26) {
                    uploadImgArr = uploadImgArr.filter((item)=>{
                        return item.step == 2
                    });
                } else {
                    uploadImgArr = uploadImgArr.filter((item)=>{
                        return item.step == 1
                    });
                }
            }

            if(npsUpload){
                uploadImgArr = [npsItem]
            }
            this.uploadImgArr = JSON.parse(JSON.stringify(uploadImgArr));
            this.uploadImgArr = this.changeImgDisabledUpload(this.uploadImgArr);
            this.otherImg.imgList = imgArr;
        },
        // 不合格照片
        getUnqualifiedImg(){
            this.unqualifiedImg = [];
            let picture = this.orderInfo.picture;
            if (!picture || picture.length == 0) {
                return;
            }
            picture.map((item) => {
                // 不合格照片
                if(item.audit_status == 2){
                    this.unqualifiedImg.push(item);
                }
            })
            if(this.unqualifiedImg.length != 0 && this.photoTemplate.length != 0){
                this.$nextTick(()=>{
                    this.$refs['unqualified'].initData();
                })
            }
        },
        // 拨打电话
        callPhone(index){
            let tel;
            if(index == 1){
                // 平台service_phone
                let service_phone = this.orderInfo.firm.service_phone;
                if(service_phone){
                    tel = `tel:${service_phone}`;
                    window.location.href = tel;
                }else{
                    this.$toast('联系电话错误！');
                    return
                }
            }else{
                // 车主
                this.myMobile = this.orderInfo.driver && this.orderInfo.driver.mobile ? this.orderInfo.driver.mobile : '';
                if(this.orderInfo.cid == 6){
                    if(this.orderInfo.phone){
                        tel = `tel:${this.orderInfo.phone}`;
                        window.location.href = tel;
                    }else{
                        this.$toast('联系电话错误！');
                        return
                    }
                }else{
                    this.affirm_phone_dialog = true;
                    if(!this.myMobile){
                        this.$nextTick(() => {
                            this.$refs.inputRef.focus();
                        })
                    }
                }
            }
        },
        // 修改电话
        editPhone(){
            this.$nextTick(() => {
                this.$refs.inputRef.focus();
            })
        },
        // 虚拟号拨打
        affirmMonbile(action, done){
            let that = this;
            if (action === 'confirm') {
                let mobile = that.myMobile;
                if(mobile && mobile.length == 11){
                    // 获取虚拟号
                    let data = {
                        order: this.order,
                        mobile: mobile,
                        act: 'mobile'
                    }
                    callContact(data).then((res)=>{
                        if(res.data.code == 1){
                            let phone = res.data.data.mobile;
                            let tel = `tel:${phone}`;
                            window.location.href = tel;
                            let obj = {
                                order: this.order,
                                act: ''
                            };
                            callContact(obj);
                            done();
                        }else{
                            done(false)
                            that.$toast(res.data.info);
                        }
                    }).catch(()=>{
                        done(false)
                    })
                }else{
                    that.$toast('请输入合法手机号！');
                    done(false)
                }
            }else{
                done();
            }
        },
        // 复制订单号
        onCopy(){
            let text = this.orderInfo.no;
            this.$copyText(text).then(()=>{
                this.$toast('订单号复制成功');
            }).catch(()=>{
                this.$toast('复制失败');
            })
        },
        // 查看现场照片
        previewImg(){
            let images = [];
            let customerImg = this.orderInfo.customerImg;
            customerImg.forEach((item)=>{
                images.push(item.doman + item.picture);
            });
            ImagePreview(images);
        },
        // 打开地图导航
        openMap(index){
            let url;
            if(index == 1){
                // 救援地theodolitic
                if(!validLngLat(this.orderInfo.theodolitic)){
                    this.$toast('故障车位置异常，请联系车主!');
                    return
                }
                let name = this.orderInfo.address;
                url = `http://uri.amap.com/marker?position=${this.orderInfo.theodolitic}&name=${name}&coordinate=gaode&callnative=1`;
            }else if(index == 2){
                // 目的地transit
                if(!validLngLat(this.orderInfo.transit)){
                    this.$toast('目的地位置异常，请联系车主!');
                    return
                }
                let name = this.orderInfo.destination;
                url = `http://uri.amap.com/marker?position=${this.orderInfo.transit}&name=${name}&coordinate=gaode&callnative=1`;
            }
            url && window.open(url);
        },
        // 服务商 、 技师接单
        receiving(){
            if(this.orderInfo.status == 31){
                // 服务商
                this.driver_phone_show = true;
            }else{
                // 技师接单
                // this.confirmReceiving();
                this.driverInfoValid();
            }
        },
        // 确认接单
        async confirmReceiving(){
            let that = this;
            this.$toast.loading({
                message: '接单中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let location;

            // location = {
            //     lat: 22.222222,
            //     lng: 113.1115555,
            //     address: '测试地址'
            // }

            try{
                location = await getGeoLocation();
            }catch(err){
                location = that.location;
            }
            
            if(!location || !location.lng || !location.lat){
                this.$toast.clear();
                this.$notify({
                    type: 'danger',
                    message: '获取当前位置失败！'
                })
                return
            }
            let data = {
                order: this.order,
                address: location.address,
                lnglat: location.lng + ',' + location.lat,
                mobile: '',
                device: that.device,
                version: 'H5',
                network: ''
            }
            if(that.driverPhone){
                // 已受理接单
                data.mobile = that.driverPhone
                that.driverPhone = '';
            }
            driverAccept(data).then((res)=>{
                this.$toast.clear();
                console.log(res, '待接单接单')
                this.$toast(res.data.info);
                if(res.data.code == 1){
                    this.getDetail();
                }
            })
        },
        driverInfoValid(phone){
            return new Promise((resolve, reject)=>{
                 /*
                    手机号检测、救援车辆车头照检测
                    订单凭证模板配置了救援车辆车头照的接单前需上传车头照
                    归属服务商   已实名==>接单
                                未实名==>完善信息==>接单
                    归属个体    已实名==>上传车头照==>接单
                                未实名==>上传姓名手机号车头照==>接单
                    未注册      上传姓名手机号车头照==>接单

                    phone   有值未指派技师，手动输入手机号接单

                    validType   1: 完善姓名手机号车头照     2：完善姓名手机号   3： 完善车头照
                */ 
                console.log(this.uploadImgArr, '----')
                let photoConfig = this.uploadImgArr;
                this.driverImproveInfo = {
                    phone: '',          //手机号
                    name: '',
                    uploadKey: '',      //救援车牌照key
                    validType: '',      //完善信息类型  1: 完善姓名手机号车头照     2：完善姓名手机号   3： 完善车头照
                    uploadData: '',     //订单流程车牌照片上传参数
                }
                for(let i=0; i<photoConfig.length; i++){
                    if(photoConfig[i].key == 'zmpstcdcphtx' || photoConfig[i].key == 'Rescue_driver_photo'){
                        this.driverImproveInfo.uploadKey = photoConfig[i].key
                        this.driverImproveInfo.uploadKeyName = photoConfig[i].name
                        break
                    }
                }
                this.submitType = 1;
                if(phone){
                    // 未指派技师
                    let params = {
                        mobile: phone
                    }
                    driverCheck(params).then((res)=>{
                        console.log(res, '手机号检测')
                        this.driverImproveInfo.phone = phone
                        if(res.code == 0){
                            // 未注册司机 完善姓名手机号车头照
                            this.driverImproveInfo.validType = 1
                        }else{
                            let info = res.data.data;
                            this.driverImproveInfo.name = info.name
                            if(info.firm_id){
                                console.log('info.real_status', info.real_status)
                                if(info.real_status == 1){
                                    // 已实名 区分是否配置救援车牌凭证 接单
                                    if(this.driverImproveInfo.uploadKey){
                                        this.driverImproveInfo.validType = 3
                                    }else{
                                        this.confirmReceiving(phone);
                                    }
                                }else{
                                    // 未实名   提交姓名手机号接单
                                    this.driverImproveInfo.validType = 2
                                }
                            }else{
                                // 个体司机
                                if(info.real_status == 1){
                                    // 已实名 上传车头照接单
                                    this.driverImproveInfo.validType = 3
                                }else{
                                    // 未实名 完善姓名手机号车头照
                                    this.driverImproveInfo.validType = 1
                                }
                            }
                        }
                        console.log(this.driverImproveInfo, 'this.driverImproveInfo1')
                        if(this.driverImproveInfo.validType){
                            this.driverInfoVsi = true;
                        }
                        resolve()
                    }).catch((err)=>{
                        resolve(false)
                    })
                }else{
                    console.log(this.driverImproveInfo, 'this.driverImproveInfo2')
                    if(this.driverImproveInfo.uploadKey){
                        this.driverImproveInfo.name = this.orderInfo.driver.nick
                        this.driverImproveInfo.phone = this.orderInfo.driver.mobile
                        this.driverInfoVsi = true;
                    }else{
                        // 接单
                        this.confirmReceiving();
                    }
                }
            })
        },
        // 提交成功
        improveSuccess(){
            console.log(this.driverImproveInfo.validType)
            if(this.submitType == 1){
                // 接单
                this.confirmReceiving();
            }else{
                // 订单详情
                this.getDetail()
            }
            this.driverInfoVsi = false;
            this.driverImproveInfo = {
                phone: '',          //手机号
                name: '',
                uploadKey: '',      //救援车牌照key
                validType: '',      //完善信息类型  1: 完善姓名手机号车头照     2：完善姓名手机号   3： 完善车头照
                uploadData: '',     //订单流程车牌照片上传参数
            }
        },
        // 关闭完善
        closeImprove(){
            this.driverInfoVsi = false;
            this.driverImproveInfo = {
                phone: '',          //手机号
                name: '',
                uploadKey: '',      //救援车牌照key
                validType: '',      //完善信息类型  1: 完善姓名手机号车头照     2：完善姓名手机号   3： 完善车头照
                uploadData: '',     //订单流程车牌照片上传参数
            }
            if(this.submitType == 2){
                this.uploadImgArr[this.carImgIndex].imgList = []
            }
        },
        // 技师确认接单
        handImproveInfo(action, done){
            let that = this;
            if (action === 'confirm') {
                if(!that.driverPhone || that.driverPhone.length != 11){
                    that.$toast('请输入合法手机号！');
                    done(false)
                }else{
                    done(that.driverInfoValid(that.driverPhone))
                }
            }else{
                that.driverPhone = '';
                done()
            }
        },
        // dialog关闭回调
        async configReceiving(action, done){
            let that = this;
            if (action === 'confirm') {
                if(!that.driverPhone || that.driverPhone.length != 11){
                    that.$toast('请输入合法手机号！');
                    done(false)
                }else{
                    that.$toast.loading({
                        message: '接单中...',
                        forbidClick: true,
                        overlay: true,
                        duration: 0,
                        loadingType: 'spinner',
                    });
                    // 接单
                    let location;
                    try{
                        location = await getGeoLocation();
                    }catch(err){
                        location = that.location;
                    }
                    if(!location || !location.lng || !location.lat){
                        this.$toast.clear();
                        this.$notify({
                            type: 'danger',
                            message: '获取当前位置失败！'
                        })
                        done(false);
                        return
                    }
                    let data = {
                        order: that.order,
                        address: location.address,
                        lnglat: location.lng + ',' + location.lat,
                        mobile: that.driverPhone,
                        device: that.device,
                        version: 'H5',
                        network: ''
                    }
                    driverAccept(data).then((res)=>{
                        console.log(res, '已受理接单');
                        that.$toast.clear();
                        that.$toast(res.data.info);
                        if(res.data.code == 1){
                            that.getDetail();
                            done();
                            that.driverPhone = '';
                        }else{
                            done(false);
                        }
                    }).catch(()=>{
                        done(false);
                    })
                }
            }else{
                that.driverPhone = '';
                done()
            }
        },
        // 到达救援地效验手机号
        validMobile(){
            this.getDetail('valid')
        },
        inputFocus(){
            this.$refs.input.focus();
        },
        changeInput(){
            setTimeout(()=>{
                if(this.validValue.length == this.valid_length){
                    // this.checkPhone(this.validValue);
                    console.log(this.validValue, '接口效验')
                    let data = {
                        order: this.order,
                        code: this.validValue
                    };
                    validMobile(data).then((res)=>{
                        if(res.data.data){
                            this.sumitArriveStart();
                            this.$refs.input.blur();
                            this.mobile_valid_dialog = false;
                        }else{
                            this.checkPhoneStatus = false;
                        }
                    })
                }
                if(this.validValue.length < this.valid_length){
                    this.checkPhoneStatus = true;
                }
            }, 0)
        },
        // 到达救援地
        async sumitArriveStart(){
            let that = this;
            this.$toast.loading({
                message: '正在操作中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let location;
            try{
                location = await getGeoLocation();
            }catch(err){
                location = that.location;
            }
            if(!location || !location.lng || !location.lat){
                this.$toast.clear();
                this.$notify({
                    type: 'danger',
                    message: '获取当前位置失败！'
                })
                return
            }
            let data = {
                order: this.order,
                address: location.address,
                lnglat: location.lng + ',' + location.lat
            }
            arrivestart(data).then((res)=>{
                console.log(res, '到达救援地');
                this.$toast.clear();
                if(res.data.code == 1){
                    this.getDetail();
                }else{
                    this.$toast(res.data.info);
                }
            })
        },
        // 关闭效验框
        closeDialog(){
            this.mobile_valid_dialog = false;
            this.validValue = '';
        },
        //由于照片上传加了顺序限制，控制照片是否可传
        changeImgDisabledUpload(list){
          let disabledNo = 0;
          list.forEach((item)=>{
            item.disabledUpload = false
            if(item.is_need && (!item.imgList || item.imgList.length <=0)){
              disabledNo >0 && (item.disabledUpload = true);
              ++disabledNo
            }
          })
          return list;
        },
        // 上传文件
        afterRead(file, index, key, keyName){
            console.log(index, file);
            let obj = {
                url: '',
                status: 'uploading',
                message: '上传中...',
            };
            this.uploadImgArr[index].imgList = [obj];
            // 救援车车牌照检测
            if(key == 'zmpstcdcphtx' || key == 'Rescue_driver_photo'){
                this.ocrCarImgValid(file, index, key, keyName)
            }else{
                this.beforeOrderImgUpload(file.file, index, key, keyName);
            }
        },
        // 检测救援车车牌照
        ocrCarImgValid(file, index, key, keyName){
            let formData = new FormData();
            formData.append('file', file.file)

            licenseOCR(formData).then((res)=>{
                console.log(file, '---------')
                if(res.data.code == 1){
                    let data = res.data.data
                    let plateNumber = data.plate_number;
                    let pic_key = data.key;
                    let pic_url = data.url;

                    if(plateNumber == this.orderInfo.car_no){
                        this.$notify({ 
                            type: 'danger',
                            message: '救援车辆车牌不能与车主一致！请重新上传'
                        });
                        this.uploadImgArr[index].imgList = [];
                        return
                    }
                    let driverLicense = this.orderInfo.driver ? this.orderInfo.driver.license : ''
                    if(driverLicense && plateNumber == driverLicense){
                        this.beforeOrderImgUpload(file.file, index, key, keyName);
                        return
                    }
                    // 车牌不一致需重新提交
                    this.driverImproveInfo.name = this.orderInfo.driver.nick
                    this.driverImproveInfo.phone = this.orderInfo.driver.mobile
                    this.driverImproveInfo.uploadKey = key
                    this.driverImproveInfo.uploadKeyName = keyName
                    this.driverImproveInfo.uploadData = {
                        file: file,
                        pic_key,
                        pic_url,
                        plateNumber
                    }
                    this.submitType = 2
                    this.carImgIndex = index;
                    this.driverInfoVsi = true
                }else{
                    this.$notify({ 
                        type: 'danger',
                        message: res.data.info
                    });
                    this.uploadImgArr[index].imgList = [];
                }
            }).catch(()=>{
                this.uploadImgArr[index].imgList = [];
                this.$toast.clear();
            })
        },
        beforeOrderImgUpload(file, index, key, keyName) {
            const _this = this;
            if(!this.location || !this.location.lat || !this.location.lng || !this.location.address){
                this.$notify({ 
                    type: 'danger',
                    message: '正在获取定位，请稍后重试!'
                });
                this.uploadImgArr[index].imgList = [];
                return
            }
            let reader = new FileReader();
            reader.readAsDataURL(file);

            let configPrams = {
                configData: this.configData,
                orderInfo: this.orderInfo,
                location: this.location,
                driverInfo: this.driverInfo,
                orderRemark: this.orderRemark,
                keyName,
                imgKey: key
            }
            let configData = initWatermarkConfigData(configPrams);       //水印配置文件

            reader.onload = function(theFile) {
                let image = new Image();
                image.src = theFile.target.result;
                image.onload = function() {
                    let data = {
                        img: image,
                        w: this.width,
                        h: this.height,
                        configData,
                    }
                    generateCanvasImg(data).then((url)=>{
                        _this.uploadImg(url, index, key, configData.watermarkTime)
                    }).catch((err)=>{
                        _this.$notify({ 
                            type: 'danger',
                            message: 'OSS上传失败!'
                        });
                        _this.uploadImgArr[index].imgList = [];
                    })
                }
            }
        },
        // 上传服务照片阿里云链接到服务器
        uploadImg(url, index, key, watermarkTime){
            if(url){
                let data = {
                    order: this.order,
                    key,
                    ser: 1,
                    url,
                    watermark_time: watermarkTime
                }
                driverUploadPic(data).then((res)=>{
                    if(res.data.code == 1){
                        let obj = {
                            url: url,
                            status: 'done',
                            message: '上传成功'
                        };
                        this.uploadImgArr[index].id = res.data.data[0].id;
                        this.uploadImgArr[index].imgList = [obj];
                        //修改图片的可传状态
                        this.uploadImgArr = this.changeImgDisabledUpload(this.uploadImgArr);
                        console.log(this.uploadImgArr[index], 'this.uploadImgArr[index]')
                        if(this.npsUpload){
                            this.$toast('上传成功！')
                            this.npsUpload = false;
                        }
                    }else{
                        this.$notify({ 
                            type: 'danger',
                            message: res.data.info
                        });
                        this.uploadImgArr[index].imgList = [];
                    }
                }).catch(()=>{
                    this.$notify({ 
                        type: 'danger',
                        message: '服务器错误'
                    });
                    this.uploadImgArr[index].imgList = [];
                })
            }else{
                this.$notify({ 
                    type: 'danger',
                    message: '照片上传失败!'
                });
                this.uploadImgArr[index].imgList = [];
            }
        },
        // 补传照片
        photosAfterRead(file){
            console.log(file, '补传照片文件');
            let that = this;
            if(Array.isArray(file)){
                // 数组
                file.forEach((e, index)=>{
                    e.status = 'uploading';
                    e.message = '上传中...';
                    this.uploadOtherImg(e.file, 'repair').then((res)=>{
                        console.log(res, 'promise1')
                        e.status = 'done';
                        e.message = '上传成功';
                        e.url = res.url;
                    }).catch((err)=>{
                        e.status = 'failed';
                        e.message = err;
                    })
                });
            }else{
                // 对象
                file.status = 'uploading';
                file.message = '上传中...';
                this.uploadOtherImg(file.file, 'repair').then((res)=>{
                    console.log(res, 'promise2')
                    file.status = 'done';
                    file.message = '上传成功';
                    file.url = res.url;
                }).catch((err)=>{
                    file.status = 'failed';
                    file.message = err;
                })
            }
        },
        // 上传其他照片
        otherAfterRead(file){
            console.log(file, '其他照片文件');
            if(Array.isArray(file)){
                // 数组
                file.forEach((e, index)=>{
                    e.status = 'uploading';
                    e.message = '上传中...';
                    this.uploadOtherImg(e.file).then((res)=>{
                        e.status = 'done';
                        e.message = '上传成功';
                        e.id = res.id;
                        e.url = res.url;
                    }).catch((err)=>{
                        e.status = 'failed';
                        e.message = err;
                    })
                });
            }else{
                // 对象
                file.status = 'uploading';
                file.message = '上传中...';
                this.uploadOtherImg(file.file).then((res)=>{
                    console.log(res, 'promise')
                    file.status = 'done';
                    file.message = '上传成功';
                    file.id = res.id;
                    file.url = res.url;
                }).catch((err)=>{
                    file.status = 'failed';
                    file.message = err;
                })
            }
        },
        // 上传其他照片阿里云链接到服务器
        uploadOtherImg(file, uploadType){
            let that = this;
            return new Promise((resolve, reject)=>{
                if(!this.location || !this.location.lat || !this.location.lng || !this.location.address){
                    reject('获取定位失败！');
                }
                let reader = new FileReader();
                reader.readAsDataURL(file);
                
                
                let configPrams = {
                    configData: this.configData,
                    orderInfo: this.orderInfo,
                    location: this.location,
                    driverInfo: this.driverInfo,
                    keyName: '其它照片',
                    imgKey: 'other'
                }
                let configData = initWatermarkConfigData(configPrams);       //水印配置文件

                reader.onload = function(theFile) {
                    let image = new Image();
                    image.src = theFile.target.result;
                    image.onload = function() {
                        let data = {
                            img: image,
                            w: this.width,
                            h: this.height,
                            configData,
                        }
                        console.log(data, 'data')
                        generateCanvasImg(data).then((url)=>{
                            if(uploadType == 'repair'){
                                // 补传照片
                                let repairObj = {
                                    url: url
                                }
                                resolve(repairObj);
                                return
                            }
                            let ser = file.lastModified || 1;
                            let uploadData = {
                                order: that.order,
                                key: 'other',
                                ser,
                                url,
                                watermark_time: configData.watermarkTime
                            };
                            driverUploadPic(uploadData).then((res)=>{
                                if(res.data.code == 1){
                                    console.log(res)
                                    let uploadObj = {
                                        id: res.data.data[0].id,
                                        url: url
                                    }
                                    resolve(uploadObj)
                                }else{
                                    reject(res.data.info);
                                }
                            }).catch(()=>{
                                reject('服务器错误');
                            })
                        }).catch((err)=>{
                            reject('OSS上传失败');
                        })
                    }
                }
            })
        },
        // 删除照片
        delImage(file, index, id){
            console.log(file, index, id, '删除照片')
            if(id){
                let data = {
                    order: this.order,
                    picture_id: id
                }
                driverUploadPic(data).then((res)=>{
                    if(res.data.code == 1){
                        this.uploadImgArr[index].imgList = [];
                        return true
                    }else{
                        this.$toast(res.data.info);
                        return false
                    }
                }).catch(()=>{
                    return false
                })
            }else{
                this.uploadImgArr[index].imgList = [];
                return true;
            }
        },
        // 删除其他照片
        delOtherImage(file){
            console.log(file);
            if(file.id){
                let data = {
                    order: this.order,
                    picture_id: file.id
                }
                driverUploadPic(data).then((res)=>{
                    if(res.data.code == 1){
                        this.otherImg.imgList.forEach((item, index)=>{
                            if(item.id == file.id){
                                this.otherImg.imgList.splice(index, 1)
                            }
                        })
                        return true
                    }else{
                        this.$toast(res.data.info);
                        return false
                    }
                }).catch(()=>{
                    return false
                })
            }else{
                return true
            }
        },
        validImgs(imgs){
            let status = true;
            if(imgs.length == 0){
                this.$notify({ 
                    type: 'danger',
                    message: `系统错误！`
                });
                status = false;
            }else{
                for(let i=0; i<imgs.length; i++){
                    let img = imgs[i];
                    if(img.is_need == 1 && (!img.imgList || img.imgList.length == 0 || !img.imgList[0].url)){
                        this.$notify({ 
                            type: 'danger',
                            message: `请上传${img.name}`
                        });
                        status = false;
                        break;
                    }
                }
            }
            return status
        },
        // 支付状态效验
        validPayStatus(){
            if(this.orderInfo.is_cash == 1){
                let pay_status = this.orderInfo.pay_status;
                if(pay_status == 0){
                    this.$notify({
                        type: 'danger',
                        message: '订单未支付'
                    })
                    return false
                }
                if(pay_status == 2){
                    this.$notify({
                        type: 'danger',
                        message: '订单尾款待支付，请引导客户支付'
                    })
                    return false
                }
                return true
            }else{
                return true
            }
        },
        // 服务完成 or 前往目的地
        orderComplete(){
            if(!this.validPayStatus()) return

            let uploadImgArr = this.uploadImgArr;
            
            let status = this.validImgs(uploadImgArr)
            if(!status) return

            if(this.ownersignConfig.is_need == 1 && !this.owner_sign && this.orderInfo.cid != 6){
                this.$notify({ 
                    type: 'danger',
                    message: `请上传${this.ownersignConfig.name}!`
                });
                return
            };
            if(this.isDoubleAddress){
                // 前往拖车目的地
                this.sumitToEnd();
            }else{
                // this.workForm.go_distance = this.orderInfo.go_distance;
                // console.log(this.workForm.go_distance, 'this.orderInfo.go_distance')
                // this.workOrder_dialog = true;
                this.completion();
            }
        },
        // 前往目的地
        async sumitToEnd(){
            let that = this;
            this.$toast.loading({
                message: '正在操作中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let location;
            try{
                location = await getGeoLocation();
            }catch(err){
                location = that.location;
            }
            if(!location || !location.lng || !location.lat){
                this.$toast.clear();
                this.$notify({
                    type: 'danger',
                    message: '获取当前位置失败！'
                })
                return
            }
            let data = {
                order: this.order,
                address: location.address,
                lnglat: location.lng + ',' + location.lat
            }
            toend(data).then((res)=>{
                console.log(res, '前往目的地');
                this.$toast.clear();
                if(res.data.code == 1){
                    this.getDetail();
                }else{
                    this.$toast(res.data.info);
                }
            })
        },
        // 到达目的地
        async sumitArriveEnd(){
            let that = this;
            this.$toast.loading({
                message: '正在操作中...',
                forbidClick: true,
                overlay: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let location;
            try{
                location = await getGeoLocation();
            }catch(err){
                location = that.location;
            }
            if(!location || !location.lng || !location.lat){
                this.$toast.clear();
                this.$notify({
                    type: 'danger',
                    message: '获取当前位置失败！'
                })
                return
            }
            let data = {
                order: this.order,
                address: location.address,
                lnglat: location.lng + ',' + location.lat
            }
            arriveend(data).then((res)=>{
                console.log(res, '到达目的地');
                this.$toast.clear();
                if(res.data.code == 1){
                    this.getDetail();
                }else{
                    this.$toast(res.data.info);
                }
            })
        },
        openWorkDialog(){
            this.workForm.go_distance = this.orderInfo.go_distance;
            this.workForm.use_wheels = this.orderInfo.use_wheels;
            this.workForm.science = this.orderInfo.science;
            this.workForm.get_distance = this.orderInfo.get_distance;
            this.workOrder_dialog = true;
        },
        // 确认工单
        submitWorkOrder(action, done){
            let that = this;
            if (action === 'confirm') {
                console.log(this.workForm, '提交工单');
                // 效验规则 里程可为0 必填
                if(this.workForm.go_distance !== ''){
                    if(this.isDoubleAddress){
                        if(this.workForm.get_distance !== ''){
                            let data = {
                                order: this.order,
                                go_distance: this.workForm.go_distance,
                                get_distance: this.workForm.get_distance,
                                science: this.workForm.science,
                                use_wheels: this.workForm.use_wheels
                            };
                            confirmWork(data).then((res)=>{
                                if(res.data.code == 1){
                                    done();
                                    this.completion();
                                }else{
                                    this.$notify({
                                        type: 'danger',
                                        message: res.data.info
                                    })
                                    done(false)
                                }
                            }).catch(()=>{
                                done(false)
                            })
                        }else{
                            this.$notify({
                                type: 'danger',
                                message: '请填写救援地到目的地行驶里程！'
                            })
                            done(false)
                        }
                    }else{
                        let data = {
                            order: this.order,
                            go_distance: this.workForm.go_distance
                        };
                        confirmWork(data).then((res)=>{
                            if(res.data.code == 1){
                                done();
                                this.completion();
                            }else{
                                this.$notify({
                                    type: 'danger',
                                    message: res.data.info
                                })
                                done(false)
                            }
                        }).catch(()=>{
                            done(false)
                        })
                    }
                }else{
                    this.$notify({
                        type: 'danger',
                        message: '请填写出发地到救援地行驶里程！'
                    })
                    done(false)
                }
            }else{
                done();
            }
        },
        // 凭证照片效验
        arrvieEndValidPhoto(){
            
            if(!this.validPayStatus()) return

            let uploadImgArr = this.uploadImgArr;

            let status = this.validImgs(uploadImgArr)
            if(!status) return

            if(this.meetsignConfig.is_need == 1 && !this.meet_sign && this.orderInfo.cid != 6){
                this.$notify({ 
                    type: 'danger',
                    message: `请上传${this.meetsignConfig.name}`
                });
                return
            }
            this.completion();
        },
        // 服务完成
        completion(){
            if(this.driversignConfig.is_need == 1 && !this.driver_sign && this.orderInfo.cid != 6){
                this.$notify({ 
                    type: 'danger',
                    message: `请上传${this.driversignConfig.name}`
                });
                return
            };
            let data = {
                order: this.order,
                address: this.location.address,
                lnglat: this.location.lng + ',' + this.location.lat
            };
            orderFinish(data).then((res)=>{
                if(res.data.code == 1){
                    this.getDetail();
                }else{
                    this.$notify({
                        type: 'danger',
                        message: res.data.info
                    });
                }
            })
        },
        // 确认选择救援环境
        popupConfirm(e){
            console.log(e, '确认选择救援环境');
            this.workForm.science = e.value;
            this.dropdownItemShow = false;
        },
        // 补传倒计时结束
        countDownFinish(e){
            this.countDownTime = 0;
        },
        // 下载专属接单APP
        downloadAPP(){
            let url = '';
            if(process.env.VUE_APP_BASE_API == 'https://v1.api.yijianjiuyuan.com'){
                url = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.onekey.driver';
                window.open(url);
            }else{
                this.$router.push({
                    path: '/app',
                    query: {
                        type: 0
                    }
                })
                return
            }
        },
        // 补传照片
        uploadPhotos(){
            this.upload_photos_dialog = true;
        },
        // 提交补传的照片
        submitPhotos(action, done){
            console.log(this.photos);
            let that = this;
            if (action === 'confirm') {
                if(that.photos.length != 0){
                    let arr = [];
                    for(let i=0; i<this.photos.length; i++){
                        let item = this.photos[i];
                         if(!item.url){
                            if(item.status == "failed"){
                                this.$notify({
                                    type: 'danger',
                                    message: '请将上传失败的照片删除后提交！'
                                });
                            }else{
                                this.$notify({
                                    type: 'danger',
                                    message: '正在上传中，请稍后提交！'
                                });
                            }
                            done(false);
                            return
                        }else{
                            arr.push(item.url);
                        }
                    }
                    let data = {
                        order: this.order,
                        key: 'other',
                        url: arr.toString()
                    }
                    driverUploadPic(data).then((res)=>{
                        if(res.data.code == 1){
                            done();
                            this.$notify({
                                type: 'success',
                                message: '提交成功'
                            });
                            this.photos = [];
                        }else{
                            this.$notify({
                                type: 'danger',
                                message: res.data.info
                            });
                            done(false);
                        }
                    }).catch(()=>{
                        done(false);
                    })
                }else{
                    this.$notify({
                        type: 'danger',
                        message: '请先上传照片！'
                    });
                    done(false);
                }
            }else{
                this.photos = [];
                done();
            }
        },
        // 签名
        toSign(key){
            this.signKey = key;
            this.signVsi = true;
        },
        // 图片预览
        preImg(url){
            ImagePreview([url]);
        },
        // 签名上传成功
        signSuccess(url){
            if(this.signKey == 'owner_sign'){
                this.owner_sign = url
            }else if(this.signKey == 'meet_sign'){
                this.meet_sign = url
            }else if(this.signKey == 'driver_sign'){
                this.driver_sign = url
                localStorage.setItem('locDriverSign', url)
            };
            this.signVsi = false;
        },
        // 上传司机缓存签名
        async uploadCacheSign(){
            let key = 'driver_sign';
            let url = this.locDriverSign;
            let data = {
                order: this.order,
                key,
                ser: 1,
                url
            }
            driverUploadPic(data).then((res)=>{
                if(res.data.code == 1){
                    this.driver_sign = url;
                }else{
                    this.$toast(res.data.info);
                }
            }).catch(()=>{
                this.$toast('上传失败');
            })
        },
        // 打开收款码
        openWxPay(){
            this.wxPayDialog = true;
        },
        // 支付成功
        paySuccess(){
            this.wxPayDialog = false;
            this.getDetail()
        }
    },
    filters: {
        filterStatus(e){
            if(e.cancel_waiting == 1){
                return '取消等待'
            };
            if(e.cancel_waiting == 2){
                return '空驶等待'
            };
            let val = e.status;
            if(val || val == 0){
				let orderStatus = Data.orderStatus;
				return orderStatus[val];
			}else{
				return '-'
			}
        },
        filterTime(val){
			if(val){
				return formatTime(val)
			}
		},
        filterContact(val){
            if(val){
                return val.substr(0,1) + '**';
            }
        },
        filterUserPhone(val){
            if(val){
                let reg = /^(\d+)(\d{7})$/;
                let tel = val.toString().replace(reg, "$1***");
                return tel
            }
        },
        filterEnvironment(val){
            let data = {
                0: '车在路面',
                1: '车在地库',
                '': '未知'
            };
            return data[val];
        }
    }
}
</script>

<style lang='scss'>
    .imageBox{
        .van-uploader, .van-uploader__wrapper, .van-uploader__upload, .van-uploader__preview, .van-uploader__file, .van-image{
            width: 100%;
            height: 100%;
        }
        .van-uploader__upload, .van-uploader__preview{
            margin: 0;
        }
    }
    .otherImgs{
        .van-uploader__preview, .van-uploader__upload {
            width: 160px;
            height: 120px;
            margin: 10px 0 0 0;
            .van-image{
                width: 160px;
                height: 120px;
            }
        }
        .van-uploader__wrapper{
            justify-content: space-between;
        }
    }
    .affirm_phone_box{
        .van-cell{
            width: 80%;
        }
        .van-cell::after{
            border: 0;
        }
    }
    .photosBox{
        .van-uploader{
            width: 100%;
        }
        .van-uploader__wrapper{
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            .van-uploader__preview{
                margin-right: 13px;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .view{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .loadingBox{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .orderInfo{
            min-height: 100%;
            padding: 15px 15px 100px;
            box-sizing: border-box;
            .title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-weight: bold;
                .callPhone{
                    padding: 4px;
                    box-sizing: border-box;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                    color: #fff;
                    span{
                        margin-left: 6px;
                    }
                }
            }
            .line{
                border-bottom: 1px solid #EFEFEF;
				margin-top: 15px;
            }
            .order_line{
                border-bottom: 1px solid #EFEFEF;
				margin-top: 10px;
            }
            .order{
                margin-top: 15px;
                font-size: 14px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                background: rgba(255, 255, 255, 0.39);
                border-radius: 4px;
                padding: 15px 10px;
                box-sizing: border-box;
                .order_title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-weight: bold;
                    }
                    .up{
                        transition: transform 0.3s;
                        transform: rotateZ(180deg);
                    }
                    .down{
                        transition: transform 0.3s;
                        transform: rotateZ(0deg);
                    }
                }
                .info_li{
                    font-size: 14px;
                    margin-top: 18px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #141414;
                    .callPhone{
                        padding: 3px 4px;
                        box-sizing: border-box;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                        color: #fff;
                        span{
                            margin-left: 6px;
                        }
                    }
                }
                .address{
                    margin-top: 10px;
                    z-index: 9;
                    .address_text{
                        line-height: 20px;
                    }
                }
            }
        }
        .otherImgs{
            margin-top: 15px;
            font-size: 14px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            background: rgba(255, 255, 255, 0.39);
            border-radius: 4px;
            padding: 1px 10px 15px;
            box-sizing: border-box;
        }
        .uploadImgs{
            margin-top: 15px;
            font-size: 14px;
            .img_li{
                padding: 10px;
                box-sizing: border-box;
                background: rgba(255, 255, 255, 0.39);
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                margin-bottom: 10px;
                .tipText{
                    .tipName{
                        margin-bottom: 6px;
                        font-weight: bold;
                        .needText{
                            display: inline-block;
                            min-width: 50px;
                        }
                        .needColor{
                            color: #FB421A;
                        }
                    }
                    .tipRemark{
                        color: #f1a532;
                        line-height: 16px;
                    }
                }
            }
            .uploadImgBox {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                flex-wrap: nowrap;
                .uploaderBox{
                    margin-left: 4px;
                }
                .imageBox {
                    width: 160px;
                    height: 123px;
                    position: relative;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                    }
                    .textImg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: inline-block;
                        width: 42px;
                        height: 20px;
                        background: #357EFE;
                        opacity: 1;
                        border-radius: 4px 0px 4px 0px;
                        font-size: 12px;
                        font-family: PingFangSC-Medium;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 20px;
                    }
                    .imgTips {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: inline-block;
                        height: 20px;
                        background: rgba(0, 0, 0, 0.39);
                        opacity: 0.5;
                        border-radius: 0px 0px 4px 4px;
                        text-align: center;
                        line-height: 20px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular;
                        color: #FFFFFF;
                        
                    }
                }
            }
        }
        .photoTips{
          text-align: center;
          margin: 10px 0 -9px;
          font-size: 0.3rem;
          color: red;
        }
        .payTips{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 60px;
            height: 30px;
            background: #fff;
		    color: #FB421A;
            display: flex;
            align-items: center;
            justify-content: center;
            .payTipText{
                margin-left: 6px;
                font-size: 15px;
                font-weight: bold;
            }
        }
        .footer{
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            .btn{
                width: 300px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 6px;
            }
            .nextBtn{
                background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
                color: #fff;
            }
            .disableBtn{
                background: rgba(236, 236, 236, 0.39);
                color: #141414;
            }
        }
        .telInput{
            margin: 15px 0;
        }
        .affirm_phone_box{
            margin: 15px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .workOrder_box{
            font-size: 14px;
            margin: 15px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .workOrder_li{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 15px;
                .km{
                    width: 22px;
                }
                .short_input{
                    width: 80px;
                    padding: 0;
                    background: #e4e4e4;
                    margin: 0 5px;
                    border: 0;
                    padding: 0 5px;
                    box-sizing: border-box;
                }
                .long_input{
                    width: 178px;
                    padding: 0 5px;
                    background: #e4e4e4;
                    margin: 0 5px;
                    border: 0;
                    box-sizing: border-box;
                }
                .dropdownMenu{
                    min-height: 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .up{
                        transition: transform 0.3s;
                        transform: rotateZ(180deg);
                    }
                    .down{
                        transition: transform 0.3s;
                        transform: rotateZ(0deg);
                    }
                }
            }
        }
        .photosBox{
            padding: 15px 20px;
            box-sizing: border-box;
            .van-uploader__wrapper{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .validBox{
            font-size: 14px;
            .dialog_close_icon{
                position: absolute;
                top: 10px;
                right: 10px;
            }
            .phoneInput_title{
                padding: 20px 0;
		        line-height: 22px;
                text-align: center;
            }
            .phoneError{
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                .error_text{
                    text-align: center;
                    font-size: 12px;
                    color: #FF451E;
                }
            }
            .u-pop-message{
                padding: 0 18px;
                display: flex;
                justify-content: space-between;
                .valid_li{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 64px;
                    height: 64px;
                    border: 1px solid #EFEFEF;
                    border-radius: 2px;
                }
                .activeFocus{
                    border: 1px solid #357EFE;
                    z-index: 9;
                }
            }
        }
        .valid_input{
            position: fixed;
            bottom: 2000px;
            right: 0;
            left: 0;
        }
    }
    .sginBox{
        border: 1px solid #efefef;
        margin-top: 18px;
        border-radius: 5px;
        .sign_title{
            height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            box-sizing: border-box;
            font-size: 14px;
            border-radius: 5px 5px 0 0;
            background: #F9F9F9;
            border-bottom: 1px solid #efefef;
            .sign_title_name{
                color: #1F2129;
            }
            .sign_title_btn{
                color: #357EFE;
            }
        }
        .signInfo{
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            .sign_text{
                color: #BCBCBC;
            }
            .signImg{
                width: 100%;
                height: 100%;
                border-radius: 0 0 5px 5px;
            }
        }
    }
    .tipIcon {
        color: #FB421A;
    }
    
    .npsTitle{
        line-height: 44px;
        font-size: 18px;
        text-align: center;
    }
    .npsTips{
        font-size: 14px;
        color: #FB421A;
    }
    .npsUploadBtn{
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: linear-gradient(180deg, #5C9AFE 0%, #357EFE 100%);
        color: #fff;
        border-radius: 4px;
        margin: 20px auto;
    }
</style>
